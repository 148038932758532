<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard :heading="page.title">
          <listagem v-if="ehListagem" @adicionar="adicionar" @editar="editar" />
          <cadastro v-else-if="ehCadastro" @close="close" />
          <edicao v-else-if="ehEdicao" @close="close" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Listagem from './listagem/Componente.vue'
import Cadastro from './cadastro/Componente.vue'
import Edicao from './edicao/Componente.vue'

import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'

export default {
  name: 'Index',
  components: { Listagem, Cadastro, Edicao },
  data,
  computed,
  methods,
}
</script>
