import { mapState } from 'vuex'

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('gerencial', ['controlador']),
  ...mapState('patio', ['tiposOperacao']),
  
  ehEdicao() {
    return !!this.controlador.public_id
  },

  labelObjeto() {
    return this.controlador.tipo.name || 'Objeto'
  },

  // quantidadeSelecionada() {
  //   return this.controlador.objetos.length
  // },

  // selecionouTodos() {
  //   return this.quantidadeSelecionada === this.objetos.length
  // },

  // selecionouAlgum() {
  //   return this.quantidadeSelecionada > 0 && !this.selecionouTodos
  // },

  // icon() {
  //   if (this.selecionouTodos) return 'mdi-close-box'
  //   if (this.selecionouAlgum) return 'mdi-minus-box'
  //   return 'mdi-checkbox-blank-outline'
  // },
}
