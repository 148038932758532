import { mapActions, mapMutations } from 'vuex'

import { TipoControladorSenha } from '@/utils/controladores'

export const methods = {
  ...mapActions('template', ['errorMessage', 'successMessage']),
  ...mapActions('gerencial', ['addControladorDoGate']),
  ...mapMutations('gerencial', ['RESET_CONTROLADOR']),

  async save() {
    let controladores = []
    const { tipo, objetos } = this.controlador

    if (tipo.id === TipoControladorSenha.Produto.id) {
      const produtos = objetos || []
      produtos.forEach((produto) => {
        controladores.push(this.makeControlador(produto))

        produto.subprodutos.forEach((subProduto) => {
          const controlador = this.makeControlador(subProduto)
          controlador.tipo = TipoControladorSenha.SubProduto.id
          controladores.push(controlador)
        })
      })
    } else {
      objetos.forEach((objeto) => {
        const controlador = this.makeControlador(objeto)
        controladores.push(controlador)
      })
    }
    try {
      delete this.controlador.objetos
      await this.addControladorDoGate({ controladores })
      this.successMessage('Controlador cadastrado com sucesso!')
      this.$emit('close')
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },

  makeControlador(obj) {
    const { tipo, utilizador } = this.controlador

    return {
      ...this.controlador,
      tipo: tipo.id,
      utilizador: utilizador.fornecedor_id || utilizador.public_id,
      criador: this.empresaAtual.public_id,
      objeto_id: obj.public_id || obj.id,
    }
  },
}
