import { mapActions } from 'vuex'

export const methods = {
  ...mapActions('template', ['errorMessage', 'successMessage']),
  ...mapActions('gerencial', ['editControladorDoGate']),

  async save() {
    delete this.controlador.objetos
    try {
      
      await this.editControladorDoGate({
        controlador: {
          ...this.controlador,
          utilizador: this.controlador.utilizador.public_id
        }
      })
      this.successMessage("Controlador atualizado com sucesso!")
      this.$emit("close")
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
