<template>
  <v-container>
    <v-card-title class="h1 justify-center">Editar Controlador</v-card-title>
    <controlador-form @save="save" @close="$emit('close')" ref="form" />
  </v-container>
</template>

<script>
import ControladorForm from '../_components/form/ControladorForm.vue'

import { computed } from './_computed'
import { methods } from './_methods'

export default {
  name: 'Edição',
  components: { ControladorForm },
  computed,
  methods,
}
</script>
