export function data() {
  return {
    page: {
      title: 'Controladores de Senhas',
    },
    breadcrumbs: [
      {
        text: 'Gerencial',
        disabled: true,
      },
      {
        text: 'Controladores',
        disabled: false,
      },
    ],

    telas: {
      cadastro: 'cadastro',
      edicao: 'edição',
    },
    telaASerExibida: '',
  }
}
