import { mapMutations } from "vuex"

export const methods = {
  ...mapMutations("gerencial", ["UPDATE_CONTROLADOR"]),

  close() {
    this.telaASerExibida = this.telas.listagem
  },

  adicionar() {
    this.telaASerExibida = this.telas.cadastro
  },

  editar(item) {
    this.UPDATE_CONTROLADOR(item)
    this.telaASerExibida = this.telas.edicao
  }
}
