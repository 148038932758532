export const computed = {
  ehListagem() {
    return !this.telaASerExibida
  },

  ehCadastro() {
    return this.telaASerExibida === this.telas.cadastro
  },

  ehEdicao() {
    return this.telaASerExibida === this.telas.edicao
  }
}
