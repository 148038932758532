<template>
  <div class="mt-4">
    <confirm-dialog @confirm="confirm" />
    <v-card-title v-if="canList">
      Filtro
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="controladores"
      :search="search"
      sort-by="descricao"
      class="border"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Controladores registrados</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-icon
            v-if="canList"
            title="Atualizar"
            medium
            @click="getControladoresDoGate({})"
            >mdi-refresh</v-icon
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="$emit('adicionar')"
            v-if="canCreate && empresaAtual.gate"
            >Adicionar Controlador</v-btn
          >
        </v-toolbar>
      </template>
      <template v-slot:[`item.tipo`]="{ item }">
        <span>{{ item.tipo | tipoToString }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="canUpdate"
          small
          class="ma-1"
          @click="$emit('editar', item)"
          title="Editar controlador"
          >mdi-pencil</v-icon
        >
        <v-icon
          v-if="canDelete"
          small
          class="ma-1"
          @click="deleteControlador(item)"
          title="Excluir controlador"
          >mdi-delete</v-icon
        >
        <v-icon
          v-if="canUpdate"
          small
          class="ma-1"
          @click="reiniciarControlador(item)"
          title="Reiniciar sequência do controlador"
          >mdi-restart</v-icon
        >
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'
import { filters } from './_filters'

import ConfirmDialog from '@/components/template/ConfirmDialog'

export default {
  name: 'Listagem',
  components: { ConfirmDialog },
  data,
  computed,
  methods,
  filters,

  async created() {
    if (this.canList) {
      await this.getControladoresDoGate({})
    }
  },
}
</script>
