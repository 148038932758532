import { TipoControladorSenha } from '@/utils/controladores'

export function data() {
  return {
    loadingObjetos: false,
    loadingUtilizadores: false,
    loadingProdutos: false,

    valorMinimoLimitador: 10,
    tiposDoGate: [TipoControladorSenha.Servico.id],
    tipos: [
      TipoControladorSenha.Produto,
      TipoControladorSenha.SubProduto,
      TipoControladorSenha.Terminal,
      TipoControladorSenha.Servico,
    ],

    utilizadores: [],
    objetos: [],
    produtos: [],
  }
}
