var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[(!_vm.ehEdicao)?[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.tipos,"error-messages":errors,"item-text":"name","item-value":"id","label":"Tipo do Controlador","prepend-icon":"mdi-shape","hint":"Esta será a \"categoria\" do controlador","persistent-hint":"","outlined":"","required":"","return-object":""},on:{"change":_vm.handleSelectTipo},model:{value:(_vm.controlador.tipo),callback:function ($$v) {_vm.$set(_vm.controlador, "tipo", $$v)},expression:"controlador.tipo"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"utilizador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.utilizadores,"error-messages":errors,"loading":_vm.loadingUtilizadores,"menu-props":{ offsetY: true },"item-text":_vm.getUtilizadorText,"item-value":_vm.getUtilizadorValue,"label":"Utilizador","prepend-icon":"mdi-office-building","hint":"Este controlador irá gerar uma sequência isolada para este utilizador","persistent-hint":"","outlined":"","required":"","return-object":""},on:{"change":_vm.handleSelectUtilizador},model:{value:(_vm.controlador.utilizador),callback:function ($$v) {_vm.$set(_vm.controlador, "utilizador", $$v)},expression:"controlador.utilizador"}})]}}],null,true)})],1)],1),(_vm.controlador.tipo == _vm.TipoControladorSenha.SubProduto)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.produtos,"error-messages":errors,"loading":_vm.loadingProdutos,"item-text":"descricao","label":"Produto","prepend-icon":"mdi-database","persistent-hint":"","outlined":"","required":"","multiple":"","hide-selected":"","return-object":""},on:{"change":_vm.handleSelectProdutos},model:{value:(_vm.controlador.produtos),callback:function ($$v) {_vm.$set(_vm.controlador, "produtos", $$v)},expression:"controlador.produtos"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.labelObjeto,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.objetos,"error-messages":errors,"loading":_vm.loadingObjetos,"item-text":_vm.getObjetoText,"label":_vm.labelObjeto,"prepend-icon":"mdi-database","hint":("Este controlador irá gerar uma sequência isolada para este(a) " + _vm.labelObjeto),"persistent-hint":"","outlined":"","required":"","multiple":"","hide-selected":"","return-object":""},on:{"change":_vm.handleSelectObjetos},model:{value:(_vm.controlador.objetos),callback:function ($$v) {_vm.$set(_vm.controlador, "objetos", $$v)},expression:"controlador.objetos"}})]}}],null,true)})],1)],1)]:_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"limitador","rules":{ required: true, min_value: _vm.valorMinimoLimitador }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Limitador de Senha","prepend-icon":"mdi-car-speed-limiter","hint":("Ao ultrapassar esse limite (Nº " + (_vm.controlador.limitador) + ") a sequência irá reiniciar"),"persistent-hint":"","outlined":"","required":"","type":"number","min":_vm.valorMinimoLimitador},on:{"change":_vm.handleSelectLimitador},model:{value:(_vm.controlador.limitador),callback:function ($$v) {_vm.$set(_vm.controlador, "limitador", $$v)},expression:"controlador.limitador"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo de Operação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.tiposOperacao,"error-messages":errors,"item-text":"descricao","item-value":"id","label":"Tipo de Operação","prepend-icon":"mdi-database","hint":("Este controlador irá gerar uma sequência isolada para este(a) " + _vm.labelObjeto),"outlined":"","required":""},on:{"change":_vm.handleSelectTipoOperacao},model:{value:(_vm.controlador.tipo_operacao),callback:function ($$v) {_vm.$set(_vm.controlador, "tipo_operacao", $$v)},expression:"controlador.tipo_operacao"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":invalid,"value":"Salvar"},on:{"close":function($event){return _vm.$emit('close')},"save":function($event){return _vm.$emit('save')}}})],1)],1)],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }