export function data() {
  return {
    search: '',
    headers: [
      { text: 'Tipo', value: 'tipo' },
      { text: 'Tipo de operação', value: 'tipo_operacao.descricao' },
      { text: 'Utilizador', value: 'utilizador.business_name' },
      { text: 'Objeto', value: 'objeto' },
      { text: 'Limitador', value: 'limitador' },
      { text: 'Sequência Atual', value: 'sequencia_atual' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],

    controladorASerRemovido: null,
    controladorASerReiniciado: null
  }
}
