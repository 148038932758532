import { mapState } from "vuex";
import { BasicPermissions, Sequences } from "@/utils/permissions"

export const computed = {
  ...mapState("auth", ["empresaAtual"]),
  ...mapState("gerencial", ["controladores"]),

  permission() {
    return Sequences.Controladores.toString()
  },

  canList() {
    return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
  },
  canCreate() {
    return this.$canDo(
      BasicPermissions.CADASTRAR,
      this.permission
    )
  },
  canUpdate() {
    return this.$canDo(BasicPermissions.EDITAR, this.permission)
  },
  canDelete() {
    return this.$canDo(
      BasicPermissions.EXCLUIR,
      this.permission
    )
  },
}
