<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form>
      <template v-if="!ehEdicao">
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="tipo"
              rules="required"
            >
              <v-autocomplete
                v-model="controlador.tipo"
                :items="tipos"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                label="Tipo do Controlador"
                prepend-icon="mdi-shape"
                hint='Esta será a "categoria" do controlador'
                persistent-hint
                outlined
                required
                return-object
                @change="handleSelectTipo"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="utilizador"
              rules="required"
            >
              <v-autocomplete
                v-model="controlador.utilizador"
                :items="utilizadores"
                :error-messages="errors"
                :loading="loadingUtilizadores"
                :menu-props="{ offsetY: true }"
                :item-text="getUtilizadorText"
                :item-value="getUtilizadorValue"
                label="Utilizador"
                prepend-icon="mdi-office-building"
                hint="Este controlador irá gerar uma sequência isolada para este utilizador"
                persistent-hint
                outlined
                required
                return-object
                @change="handleSelectUtilizador"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          v-if="controlador.tipo == TipoControladorSenha.SubProduto"
        >
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="produto"
              rules="required"
            >
              <v-autocomplete
                v-model="controlador.produtos"
                :items="produtos"
                :error-messages="errors"
                :loading="loadingProdutos"
                item-text="descricao"
                label="Produto"
                prepend-icon="mdi-database"
                persistent-hint
                outlined
                required
                multiple
                hide-selected
                return-object
                @change="handleSelectProdutos"
              >
              </v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="labelObjeto"
              rules="required"
            >
              <v-autocomplete
                v-model="controlador.objetos"
                :items="objetos"
                :error-messages="errors"
                :loading="loadingObjetos"
                :item-text="getObjetoText"
                :label="labelObjeto"
                prepend-icon="mdi-database"
                :hint="`Este controlador irá gerar uma sequência isolada para este(a) ${labelObjeto}`"
                persistent-hint
                outlined
                required
                multiple
                hide-selected
                return-object
                @change="handleSelectObjetos"
              >
              </v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
      </template>

      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="limitador"
            :rules="{ required: true, min_value: valorMinimoLimitador }"
          >
            <v-text-field
              v-model="controlador.limitador"
              :error-messages="errors"
              label="Limitador de Senha"
              prepend-icon="mdi-car-speed-limiter"
              :hint="`Ao ultrapassar esse limite (Nº ${controlador.limitador}) a sequência irá reiniciar`"
              persistent-hint
              outlined
              required
              type="number"
              :min="valorMinimoLimitador"
              @change="handleSelectLimitador"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="Tipo de Operação"
            rules="required"
          >
            <v-autocomplete
              v-model="controlador.tipo_operacao"
              :items="tiposOperacao"
              :error-messages="errors"
              item-text="descricao"
              item-value="id"
              label="Tipo de Operação"
              prepend-icon="mdi-database"
              :hint="`Este controlador irá gerar uma sequência isolada para este(a) ${labelObjeto}`"
              outlined
              required
              @change="handleSelectTipoOperacao"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="6">
          <v-card-actions>
            <v-spacer />
            <buttons-cadastro
              @close="$emit('close')"
              @save="$emit('save')"
              :disabled="invalid"
              value="Salvar"
            />
          </v-card-actions>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { TipoControladorSenha } from '@/utils/controladores'

export default {
  name: 'ControladorForm',
  components: { ButtonsCadastro },
  data,
  computed,
  methods,
  async created() {
    this.getTipoOperacao()
    this.TipoControladorSenha = TipoControladorSenha
  },
}
</script>
