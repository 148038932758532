import patioAPI from '@/services/patio'
import gateAPI from '@/services/tenants/gates'
import contratoAPI from '@/services/tenants/contratos'

import Perfil from '@/utils/perfil'
import { TipoControladorSenha } from '@/utils/controladores'
import { mapActions, mapMutations } from 'vuex'

export const methods = {
  ...mapActions('template', ['errorMessage']),
  ...mapMutations('gerencial', ['UPDATE_ATRIBUTO_CONTROLADOR']),
  ...mapActions('patio', ['getTipoOperacao']),

  getUtilizadorValue(utilizador) {
    return utilizador.fornecedor_id || utilizador.public_id
  },

  getUtilizadorText(utilizador) {
    return utilizador.business_name
  },

  getObjetoText(objeto) {
    return objeto.business_name || objeto.descricao
  },

  close() {
    this.$emit('close')
  },

  // toggle() {
  //   if (this.selecionouTodos) {
  //     this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: "objetos", valor: [] })
  //   } else {
  //     this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: "objetos", valor: this.objetos.slice() })
  //   }
  // },

  limparEscolhas() {
    this.objetos = []
    this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: 'utilizador', valor: null })
    this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: 'objetos', valor: [] })
  },

  handleSelectTipo(tipo) {
    this.setUtilizadores(tipo)
    this.limparEscolhas()
    this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: 'tipo', valor: tipo })
  },

  async handleSelectUtilizador(tenant) {
    this.setObjeto(tenant)
    this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: 'utilizador', valor: tenant })
  },

  handleSelectObjetos(objetos) {
    this.UPDATE_ATRIBUTO_CONTROLADOR({ atributo: 'objetos', valor: objetos })
  },

  handleSelectLimitador(limitador) {
    this.UPDATE_ATRIBUTO_CONTROLADOR({
      atributo: 'limitador',
      valor: limitador,
    })
  },

  handleSelectTipoOperacao(tipo_operacao) {
    console.log(tipo_operacao)
    this.UPDATE_ATRIBUTO_CONTROLADOR({
      atributo: 'tipo_operacao',
      valor: tipo_operacao,
    })
  },
  handleSelectProdutos(produtos) {
    let subprodutos = []
    produtos.forEach((element) => {
      subprodutos = subprodutos.concat(element.subprodutos)
    })
    this.objetos = subprodutos
  },

  async handleGetFornecedores() {
    try {
      this.loadingUtilizadores = true
      this.utilizadores = await gateAPI.getFornecedores(this.empresaAtual)
    } catch (e) {
      if (e.response.status === 404) {
        this.errorMessage('Empresa não é gate')
      }
    } finally {
      this.loadingUtilizadores = false
    }
  },

  async setUtilizadores(tipo) {
    if (this.tiposDoGate.includes(tipo.id)) {
      const { tenant, public_id } = this.empresaAtual
      const self = { public_id, business_name: tenant }

      this.utilizadores = [self]
    } else {
      await this.handleGetFornecedores()
    }
  },

  async setObjeto(tenant) {
    const { tipo } = this.controlador

    switch (tipo.id) {
      case TipoControladorSenha.Produto.id:
        this.setProdutos(tenant)
        break
      case TipoControladorSenha.Terminal.id:
        await this.setTerminais(tenant)
        break
      case TipoControladorSenha.Servico.id:
        this.setServicos(tenant)
        break
      case this.TipoControladorSenha.SubProduto.id:
        this.produtos = tenant.produtos
        break
      default:
        this.errorMessage('Tipo de controlador inválido')
    }
  },

  setProdutos(tenant) {
    this.objetos = tenant.produtos
  },

  async setTerminais(tenant) {
    try {
      this.objetos = await contratoAPI.getContratos(tenant.fornecedor_id, {
        perfil: Perfil.Terminal,
      })
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },

  async setServicos(tenant) {
    try {
      this.objetos = await patioAPI.getServicos({
        proprietario: tenant.public_id,
      })
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
