import { mapActions } from "vuex";

export const methods = {
  ...mapActions("template", ["setDialogMessage", "successMessage", "errorMessage"]),
  ...mapActions("gerencial", [
    "getControladoresDoGate",
    "reiniciarControladorDoGate",
    "removerControladorDoGate"
  ]),

  deleteControlador(controlador) {
    this.controladorASerRemovido = controlador
    this.setDialogMessage({
      title: 'Confirmar exclusão?',
      message: 'Deseja mesmo excluir esse controlador?',
    })
  },

  async reiniciarControlador(controlador) {
    this.controladorASerReiniciado = controlador
    this.setDialogMessage({
      title: 'Confirmar reinicio?',
      message: 'Deseja mesmo reiniciar a contagem desse controlador?',
    })
  },

  async confirm() {
    if (this.controladorASerRemovido) {
      await this.confirmDelete()
    } else if (this.controladorASerReiniciado) {
      await this.confirmReinicio()
    }
  },

  async confirmReinicio() {
    try {
      await this.reiniciarControladorDoGate({ controlador: this.controladorASerReiniciado })
      this.successMessage("Sequência reiniciada com sucesso!")
    } catch (e) {
      this.errorMessage(e.response.data)
    } finally {
      this.controladorASerReiniciado = null
    }
  },

  async confirmDelete() {
    try {
      await this.removerControladorDoGate({ controlador: this.controladorASerRemovido })
      this.successMessage('Controlador excluído com sucesso')
    } catch (e) {
      this.errorMessage(e.response.data)
    } finally {
      this.controladorASerRemovido = null
    }
  },
}
